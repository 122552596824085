import { render, staticRenderFns } from "./MerchantLogin.vue?vue&type=template&id=51ce69a8&scoped=true&"
import script from "./MerchantLogin.vue?vue&type=script&lang=ts&"
export * from "./MerchantLogin.vue?vue&type=script&lang=ts&"
import style0 from "./MerchantLogin.vue?vue&type=style&index=0&id=51ce69a8&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51ce69a8",
  null
  
)

export default component.exports
import QPage from 'quasar/src/components/page/QPage.js';
import QForm from 'quasar/src/components/form/QForm.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QPage,QForm,QInput,QBtn});
