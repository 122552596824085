
    import {Component, Inject, InjectReactive, Vue} from "vue-property-decorator";
import {API_ENDPOINT_SYMBOL, USER_PROFILE_SYMBOL} from "@/constants";

@Component({})
export default class MerchantLogin extends Vue {
  @Inject(API_ENDPOINT_SYMBOL) apiEndPoint;
  @InjectReactive(USER_PROFILE_SYMBOL) user;
  username = "";
  password = "";

  created(){
      if(this.user){
          this.$router.replace('/profile')
      }
  }
}
